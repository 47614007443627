import { modelT } from "../../redux/features/ui/ui.types";

export const modelsOttomans: modelT[] = [
  // {
  //   img: "/images/assets/ClassicSofa/Ottoman.png",
  //   label: "Minsky Set",
  //   nameValueThreekit: "Minsky Ottomans",
  // },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Haxel Storage Footstool",
    nameValueThreekit: "Haxel Storage Footstool",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Dixy Storage Footstool",
    nameValueThreekit: "Dixy Storage Footstool",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Bell Storage Ottoman",
    nameValueThreekit: "Bell Storage Ottoman",
  },

  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Medium Rectangle",
    nameValueThreekit: "Medium Rectangle",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Medium Square Ottoman",
    nameValueThreekit: "Medium Square Ottoman",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Large Circle",
    nameValueThreekit: "Large Circle",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Large Rectangle",
    nameValueThreekit: "Large Rectangle",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Medium Circle",
    nameValueThreekit: "Medium Circle",
  },
  {
    img: "/images/assets/ClassicSofa/Ottoman.png",
    label: "Large Square Ottoman",
    nameValueThreekit: "Large Square Ottoman",
  },
];
